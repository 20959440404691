<template>
  <div class="container">
    <el-form :model="form"
             :rules="rules"
             label-position="top"
             ref="form">
      <el-form-item label="卡券类型"
                    required>
        <el-radio-group v-model="form.coupon_activity_type"
                        :disabled="form.id?true:false">
          <el-radio label="ONE">单券</el-radio>
          <el-radio label="MANY">多券</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="活动名称"
                    class="width300"
                    prop="title">
        <el-input type="text"
                  placeholder="请输入活动名称"
                  v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item label="活动图片"
                    prop="img_id">
        <ImgUpload :fileList="imgList"
                   pixel="用于页面配图，建议使用640*200像素"
                   v-model="form.img_id"></ImgUpload>
      </el-form-item>
      <el-form-item label="活动规则"
                    prop="rule">
        <el-input type="textarea"
                  :rows="4"
                  placeholder="请输入活动规则"
                  v-model="form.rule">
        </el-input>
      </el-form-item>
      <el-form-item label="活动广告图">
        <ImgUpload :fileList="advImgList"
                   pixel="用于页面配图，建议使用640*200像素"
                   v-model="form.banner_img_id" />
      </el-form-item>
      <template v-if="form.coupon_activity_type === 'MANY'">
        <el-form-item label="是否要求注册">
          <el-switch v-model="form.is_register"></el-switch>
        </el-form-item>
        <el-form-item label="用户注册信息"
                      v-if="form.is_register">
          <el-checkbox-group v-model="form.register_info">
            <el-checkbox label="TEL"
                         disabled>手机号码</el-checkbox>
            <el-checkbox label="NAME">姓名</el-checkbox>
            <el-checkbox label="LICENSE_NUM">车牌号码</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </template>
      <el-form-item label="是否首页显示">
        <el-switch v-model="form.is_index"></el-switch>
      </el-form-item>
      <el-form-item label="活动区域">
        <Address :address.sync="address"
                 @change="addressChange" />
      </el-form-item>
      <el-form-item label="是否显示在活动列表">
        <el-switch v-model="form.is_list"></el-switch>
      </el-form-item>
      <el-form-item label="是否显示在下级网点区域"
                    v-if="form.is_list">
        <el-switch v-model="form.is_child_show"></el-switch>
      </el-form-item>
      <el-form-item label="显示的网点"
                    v-if="form.is_list">
        <Area v-model="areaAddress"
              @change="areaChange" />
      </el-form-item>
      <el-form-item label="马上使用按钮提示"
                    v-if="!form.is_index"
                    prop="detail_button_text">
        <el-input type="textarea"
                  :rows="4"
                  placeholder="马上使用按钮提示"
                  v-model="form.detail_button_text">
        </el-input>
      </el-form-item>
      <el-form-item label="日期规则"
                    prop="started_at">
        <WxDate @change="dateChange"
                ref="wxDate"
                :dateRule="dateRule" />
      </el-form-item>
      <el-form-item label="优惠额度说明"
                    class="width300">
        <el-input type="text"
                  placeholder="请输入优惠额度说明"
                  v-model.number="form.privilege"></el-input>
      </el-form-item>
      <el-form-item label="活动期间每天可参与的总次数"
                    prop="day_max_num"
                    class="width300">
        <el-input type="text"
                  placeholder="请填数字，不填则以关联券总数量为准"
                  v-model.number="form.day_max_num"></el-input>
      </el-form-item>
      <el-form-item label="每个用户可参与次数"
                    prop="user_total_max_num"
                    class="width300">
        <el-input type="text"
                  placeholder="请填数字，默认为1"
                  v-model.number="form.user_total_max_num"></el-input>
      </el-form-item>
      <el-form-item label="用户每天可参与次数"
                    prop="user_day_max_num"
                    class="width300">
        <el-input type="text"
                  placeholder="请填数字，默认为1"
                  v-model.number="form.user_day_max_num"></el-input>
      </el-form-item>
      <el-form-item label="总中奖概率"
                    prop="probability"
                    class="width300">
        <el-input type="text"
                  :max="100"
                  placeholder="单位为“%”，请直接填写数字"
                  v-model.number="form.probability"></el-input>
      </el-form-item>
      <el-form-item label="关联优惠券"
                    prop="probability"
                    class="width300">
        <el-row>
          <el-select v-model="value"
                     filterable
                     remote
                     placeholder="请输入批次号"
                     :remote-method="remoteMethod"
                     @change="value = ''"
                     :loading="selectLoading">
            <el-option v-for="item in options"
                       :key="item.id"
                       :label="item.title"
                       :value="item.id">
              <div @click="onClick(item)">
                {{item.id}}-{{item.coupon_stock_id}}-{{item.title}}
              </div>
            </el-option>
          </el-select>
        </el-row>
        <el-row class="mt-15"
                type="flex">
          <el-tag type="success"
                  class="mr-15"
                  closable
                  @close="close(index)"
                  v-for="(item,index) in couponList"
                  :key="index">{{item.id}}-{{item.coupon_stock_id}}-{{item.title}}</el-tag>
        </el-row>

      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   @click="submitForm('form')">立即{{form.id?'修改':'创建'}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { fetchAllotCards, setActivity, fetchVouchers, getActivity } from '@/api/market'
import Address from '@/components/Address'
import ImgUpload from '@/components/ImgUpload'
import WxDate from '@/components/WxDate'
import Area from '@/components/Area'
import { getImgId } from '@/utils'
export default {
  name: 'SetActivity',
  data () {
    return {
      dateRule: {},
      value: '',
      couponList: [],
      imgList: [],
      advImgList: [],
      dateTime: [],
      selectLoading: false,
      address: {},
      areaAddress: {},
      coupons: [],
      vouchers: [],
      refuels: [],
      options: [],
      form: {
        type: 'COUPON',
        is_register: false, // 是否注册
        title: '',
        started_at: '',
        ended_at: '',
        rule: '',
        city: '',
        province: '',
        district: '',
        day_max_num: 0,
        user_total_max_num: 1,
        privilege: '',
        probability: 100,
        coupon_stock_ids: [],
        coupon_ids: [],
        img_id: '',
        banner_img_id: '',
        imgData: {},
        is_index: true,
        detail_button_text: '',
        coupon_activity_type: 'ONE',
        is_list: true,
        is_child_show: false, // 是否显示在下级网点区域
        list_province: '',
        list_city: '',
        list_district: '',
        list_net: '',
        time_rule: 'ANY',
        time_rule_day: [],
        time_rule_time: [],
        time_rule_week: [1, 2, 3, 4, 5, 6, 7],
        user_day_max_num: 1
      },
      rules: {
        title: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        img_id: [
          { required: true, message: '请上传图片', trigger: 'change' }
        ],
        rule: [
          { required: true, message: '请输入活动规则', trigger: 'blur' }
        ],
        detail_button_text: [
          { required: true, message: '请输入马上使用按钮提示', trigger: 'blur' }
        ],
        province: [
          { required: true, message: '请选择区域，至少为省份', trigger: 'change' }
        ],
        day_max_num: [
          { type: 'number', message: '请输入数字', trigger: 'blur' }
        ],
        user_total_max_num: [
          { required: true, message: '请输入每个用户可参与次数', trigger: 'blur' },
          { type: 'number', message: '请输入数字', trigger: 'blur' }
        ],
        user_day_max_num: [
          { required: true, message: '请输入每个用户可参与次数', trigger: 'blur' },
          { type: 'number', message: '请输入数字', trigger: 'blur' }
        ],
        started_at: [
          { required: true, message: '请选择起止时间', trigger: 'change' }
        ],
        probability: [
          { required: true, message: '请输入总中奖概率', trigger: 'blur' },
          { type: 'number', message: '请输入数字', trigger: 'blur' }
        ]
      }
    }
  },
  components: {
    Area,
    ImgUpload,
    Address,
    WxDate
  },
  methods: {
    dateChange (e) {
      this.form = Object.assign(this.form, e)
    },
    close (index) {
      this.form.coupon_ids.splice(index, 1)
      this.form.coupon_stock_ids.splice(index, 1)
      this.couponList.splice(index, 1)
    },
    onClick (item) {
      const index = this.form.coupon_ids.findIndex((id) => {
        return parseInt(id) === parseInt(item.id)
      })
      if (index !== -1) {
        this.$message.error('请勿添加相同关联券')
      } else {
        this.form.coupon_ids.push(item.id)
        this.form.coupon_stock_ids.push(item.coupon_stock_id)
        this.couponList.push(item)
      }
    },
    addressChange (res) {
      this.form.province = res.province
      this.form.city = res.city
      this.form.district = res.district
    },
    async remoteMethod (query) {
      if (query !== '' && query.length > 4) {
        this.selectLoading = true
        const res = await fetchVouchers({
          page: 1,
          size: 5,
          coupon_stock_id: query
        })
        this.selectLoading = false
        if (res.meta.code === 0) {
          this.options = res.data.coupons
        }
      } else {
        this.options = []
      }
    },
    areaChange (address) {
      const { form } = this
      form.list_province = address.province
      form.list_city = address.city
      form.list_district = address.district
      form.list_net = address.net
    },
    async fetchAllotCards (val) {
      const res = await fetchAllotCards({
        type: val
      })
      if (res.meta.code === 0) {
        if (val === 'VOUCHER') {
          this.coupons = res.data.allots
        } else if (val === 'REFUEL') {
          this.refuels = res.data.allots
        }
      }
    },
    submitForm (formName) {
      const wxDateStatus = this.$refs.wxDate.validate()
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (!wxDateStatus) {
            return
          }
          const res = await setActivity(this.form)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: `${this.form.id ? '修改' : '创建'}成功`,
              type: 'success'
            })
            this.$router.push('activities')
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          this.$message.error('表单信息填写有误, 请检查')
        }
      })
    },
    async getActivity (id) {
      const res = await getActivity({
        activity_id: id
      })
      if (res.meta.code === 0) {
        const activity = res.data.activity
        activity.activity_id = id
        activity.img_id = getImgId(activity.img_data)
        activity.img_data && this.$set(this.imgList, 0, activity.img_data)
        activity.banner_img_id = getImgId(activity.banner_img_data)
        activity.banner_img_data && this.$set(this.advImgList, 0, activity.banner_img_data)
        this.address = {
          province: activity.province,
          city: activity.city,
          district: activity.district
        }
        this.areaAddress = {
          province: activity.list_province,
          city: activity.list_city,
          district: activity.list_district,
          net: activity.list_net
        }
        this.dateRule = {
          time_rule: activity.time_rule,
          started_at: activity.started_at,
          ended_at: activity.ended_at,
          time_rule_week: activity.time_rule_week,
          time_rule_time: activity.time_rule_time,
          time_rule_day: activity.time_rule_day
        }
        activity.coupon_ids.map(async (item) => {
          const coupon = await fetchVouchers({ coupon_id: item })
          if (res.meta.code === 0) {
            this.couponList.push(coupon.data.coupons[0])
          }
        })
        this.form = activity
      }
    }
  },
  mounted () {
    this.fetchAllotCards('VOUCHER')
    if (this.$route.query.id) {
      this.getActivity(this.$route.query.id)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
